<template>
  <div class="platforms-engagement-entry">
    <Content
      class="wrapper"
      title="overall platforms engagement"
      description="View engagement metrics across all platforms in the selected time period."
    >
    </Content>
    <!-- {{ platforms }} -->
    <div v-if="$apollo.queries.platforms.loading" class="bg-white shadow-sm rounded text-center py-5 loader">
      <spinner size="4" />
    </div>
    <div class="list" v-else-if="platforms && platforms.length > 0 && !$apollo.queries.platforms.loading">
      <SinglePlatformEngagement v-for="platform in platforms" :key="platform.platform" :value="platform" />
    </div>
    <div v-else-if="platforms && platforms.length < 1 && !$apollo.queries.platforms.loading" class="empty">
      <div class="bg-white rounded text-center p-5">
        <img class="pt-3" width="300" src="@/assets/images/lost.svg" alt="" />
        <h4 class="pt-5">No social account here yet</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { createNamespacedHelpers } from 'vuex';
import Content from '~/components/Reports/partials/Content';
import SinglePlatformEngagement from '@/components/Reports/overview/partials/SinglePlatformEngagement';
import { REPORT_QUERY } from '~/graphql/queries';

const workspaceNamespace = createNamespacedHelpers('workspace');

export default {
  data() {
    return {
      platforms: [],
    };
  },
  components: {
    Content,
    SinglePlatformEngagement,
  },
  computed: {
    ...workspaceNamespace.mapState({
      workspace: state => state.current,
    }),
  },

  apollo: {
    platforms: {
      query: REPORT_QUERY,
      variables() {
        return {
          name: 'overview.platform-engagement',
          workspace: this.workspace.id,
        };
      },
      update(data) {
        const platforms = get(data, 'reports.platforms');
        return platforms;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.platforms-engagement-entry {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-content: flex-start;

  ::v-deep {
    > .list {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 0 25px 25px;
      margin: 35px 0 0 0;
      background-color: #ffffff;
      border-radius: 12px;
      justify-content: center;

      > .single-platform-engagement-entry {
        width: 420px;
        margin: 30px 40px 0 0;
      }
    }

    > .loader,
    > .empty {
      width: 100%;
      min-height: 500px;
      justify-content: center;
      align-items: center;
      display: flex;
      background-color: #ffffff;
      box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
